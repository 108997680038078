import {useState, useEffect} from "react";
import {Dialog, DialogActions, DialogContent,IconButton, Button, DialogContentText} from '@mui/material';
import CancelIcon from '@mui/icons-material/TaskAlt';
import {MsgDialog} from '.'
import {isProduction, isRemoveManualRule, addManualRule, removeManualRule} from "../utils"

/***********************************************/
export function NotamNotification (props) {

  const { notamNotificationOpen , setNotamNotificationOpen, description, originalNotam} = props
  const [notamFields , setNotamFields] = useState("")

  const [notamParams , setNotamParams] = useState({})
  const [buttonText , setButtonText] = useState("")  
  const [msgDialogOpen, setMsgDialogOpen] = useState(false);
  const [msgContent, setMsgContent] = useState("");
  
/***********************************************/

  const buttonOkClicked = ()=>{ 
      setNotamNotificationOpen(false)
  }

  /***********************************************/
  const buttonRuleClicked = ()=>{ 
    if(!isProduction()){
      console.log('buttonRuleClicked not production');

      if(isRemoveManualRule()){
        removeManualRule(notamParams, (status, data) => {
          setMsgContent("removeManualRule status: " + status + "\ndata: " + data)
          setMsgDialogOpen(true);
          setNotamNotificationOpen(false)
        })
      }else{
        addManualRule(notamParams, (status, data) => {
          setMsgContent("addManualRule status: " + status + "\ndata: " + data)
          setMsgDialogOpen(true);
          setNotamNotificationOpen(false)
        })
      }
    }
  }  

  /***********************************************/

  useEffect(() => {

    if(notamNotificationOpen && originalNotam){

      var fields = "\n"

      fields += JSON.stringify(originalNotam,null,2);

      setNotamFields(fields)
      const params = {
                      notamId: originalNotam.notamId, 
                      mapUsed: originalNotam.mapUsed, 
                      status: originalNotam.status
                     }

      setNotamParams(params)
   
      isRemoveManualRule() ? setButtonText("Remove Manual rule") : setButtonText("Add Manual rule")
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notamNotificationOpen]);


/***********************************************/
    
    return(
      
      <>
       <MsgDialog
        msgDialogOpen = {msgDialogOpen}
        msgTitle = {buttonText}
        msgContent = {msgContent}
        closeMsgDialog =  {() => setMsgDialogOpen(false)}
        trueButtonText = {"OK"}        >
      </MsgDialog>

    
      <Dialog 
        dir="rtl"
        open={notamNotificationOpen} 
        onClose={buttonOkClicked} 
        >

        <DialogContent>
            <DialogContentText > 
              {description} 
            </DialogContentText>
            <DialogContentText dir="ltr" style={{ whiteSpace: 'pre-wrap' }}> 
              {notamFields && notamFields}
            </DialogContentText>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>

          {isProduction() ? null: <Button  onClick={buttonRuleClicked}>{buttonText}</Button>}

            <IconButton 
                aria-label="ok" 
                color="primary"
                onClick={buttonOkClicked}
                ><CancelIcon />
            </IconButton>
        </DialogActions>
      </Dialog>
      </>
    )    
}

export default NotamNotification;
